import { FunctionalComponent, h } from 'preact'
import { Suspense, lazy } from 'preact/compat'
import { Route, Router } from 'preact-router'

import Loading from './loading'
const Home = lazy(() => import('../routes/home'))
const HomePrivacyPolicy = lazy(() => import('../routes/home/privacypolicy'))
const SigninOriginalDevice = lazy(() => import('../routes/home/signin_original_device'))
const AnnouncementShow = lazy(() => import('../routes/announcements/show'))
const AnnouncementList = lazy(() => import('../routes/announcements/list'))
const ArticleShow = lazy(() => import('../routes/articles/show'))
const ArticleList = lazy(() => import('../routes/articles/list'))
const Terms = lazy(() => import('../routes/terms'))
const PrivacyPolicy = lazy(() => import('../routes/privacypolicy'))
const Abj = lazy(() => import('../routes/abj'))
const Help = lazy(() => import('../routes/help'))
const FundSettlement = lazy(() => import('../routes/fund_settlement'))
const SpecifiedCommercialTransactions = lazy(() => import('../routes/specified_commercial_transactions'))
const CommentGuideline = lazy(() => import('../routes/comment_guideline'))
const NotFound = lazy(() => import('../routes/not_found'))

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if ((module as any).hot) {
  // tslint:disable-next-line:no-var-requires
  require('preact/debug')
}

const App: FunctionalComponent = () => {
  return (
    <div id="app">
      <Suspense fallback={<Loading />}>
        <Router>
          <Route path="/" component={Home} />
          <Route path="/home/privacypolicy" component={HomePrivacyPolicy} />
          <Route path="/sign-in-with-your-original-device" component={SigninOriginalDevice} />
          <Route path="/terms" component={Terms} />
          <Route path="/privacypolicy" component={PrivacyPolicy} />
          <Route path="/abj" component={Abj} />
          <Route path="/help" component={Help} />
          <Route path="/fund_settlement" component={FundSettlement} />
          <Route path="/specified_commercial_transactions" component={SpecifiedCommercialTransactions} />
          <Route path="/comment_guideline" component={CommentGuideline} />
          <Route path="/articles/:id" component={ArticleShow} />
          <Route path="/articles/categories/:id" component={ArticleList} />
          <Route path="/announcements/:id" component={AnnouncementShow} />
          <Route path="/announcements" component={AnnouncementList} />
          <NotFound default />
        </Router>
      </Suspense>
    </div>
  )
}

export default App
